import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="À propos | Simon Parisse" desc="Ce qui me passionne dans les voyages, c'est avant tout partir à la découverte de nouvelles cultures et m'immerger au cœur des populations." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Que dire à propos de moi ?</h1>
        <h4>
         Simon Parisse | Photographe amateur.
        </h4>
        <p>
          Ce qui me passionne dans les voyages, c'est avant tout partir à la découverte de nouvelles cultures et m'immerger au cœur des populations.
        </p>
        <p>
          Au détour d'une rencontre ou d'un paysage hors du commun, je souhaite partager grâce à mes clichés, des émotions et des instants authentiques.
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
